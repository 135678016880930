'use client';
import * as React from 'react';
import cn from 'mxcn';
import { useFieldError } from './Form';
import { AnimatePresence, motion } from 'framer-motion';

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  size?: 'sm' | 'md' | 'lg';
  onValueChange?: (value: string) => void;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, size, onValueChange, ...props }, ref) => {
    const error = useFieldError(props.name);
    size ??= 'md';
    return (
      <>
        <div className="stack flex-auto w-full relative">
          <input
            type={type}
            className={cn(
              'relative z-10 flex w-full font-sans rounded-sm bg-contrast-50 border border-contrast-100 shadow-sm transition-colors file:border-0 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
              size === 'sm' && 'h-7 text-xs px-2 py-0.5',
              size === 'md' && 'h-9 text-sm px-3 py-1',
              size === 'lg' && 'h-12 text-lg px-4 py-1.5',
              className
            )}
            ref={ref}
            onInput={(e) =>
              onValueChange?.((e.target as HTMLInputElement).value)
            }
            {...props}
          />
          <AnimatePresence>
            {error && (
              <motion.div
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: -20, opacity: 0 }}
                className="bg-yellow-500/40 px-1.5 pt-2 pb-1 dark:text-white rounded-b -mt-1 text-xs self-start w-full absolute left-0 top-full"
              >
                {error}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </>
    );
  }
);
Input.displayName = 'Input';

export { Input };
